@import 'varible';

* {
  font-family: 'Inter', sans-serif;
}

body,
#root {
  height: 100%;
}

@mixin make-container($minWidth, $breakpoint) {
  @media (min-width: $minWidth) {
    .container {
      max-width: $breakpoint;
    }
  }
}

@include make-container($screen-xs-min, $screen-xs);
@include make-container($screen-sm-min, $screen-sm);
@include make-container($screen-md-min, $screen-md);
@include make-container($screen-lg-min, $screen-lg);
@include make-container($screen-xl-min, $screen-xl);
@include make-container($screen-xxl-min, $screen-xxl);

:root {
  --base-unit: 7px;
}

.sd-btn:not(:disabled):hover, .sd-btn:not(:disabled):focus{
  //box-shadow: none !important;

}
.ant-menu-light {
  background: transparent !important;
  border-bottom:none !important
}
.ant-menu-title-content{
  font-size: 14px;
  font-weight: 600;
}



.sd-btn  {
  border: none;
  color: white;
  padding: 10px 32px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.sd-btn  {
  background-color: transparent !important;
  color: black;
  border: 1px solid #037c7c !important;
}

.sd-btn:hover {
  background-color: #037c7c !important;
  color: white;
  border: 1px solid #037c7c !important;
}